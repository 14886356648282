import moment from 'moment'
import { supabase } from './index'

export async function signInWithEmailAndPassword(values) {
    try {
        const { email, password } = values
        const { user, error } = await supabase.auth.signInWithPassword({
            email,
            password,
        })
        if (error) {
            throw error
        }
        return { user, error }
    } catch (err) {
        throw err
    }
}

export async function signUpWithEmailAndPassword(email, password) {
    const { data, error } = await supabase.auth.signUp({
        email,
        password,
    })

    return { data, error }
}

export async function signOutFromSupabase() {
    try {
        await supabase.auth.signOut()
    } catch (err) {
        // console.log('err',)
    }
}

export function onAuthStateChanged(callback) {
    const authListener = supabase.auth.onAuthStateChange(callback)
    return authListener.unsubscribe
}

export async function createUserProfile(user) {
    try {
        const { data, error } = await supabase
            .from('users')
            .insert({
                email: user.email,
                auth_id: user.id,
                role: 'VENDOR',
                username: user.username,
            })
            .select('*')
            .single()
        if (error) {
            throw error
        }
        await createOrUpdateVendor({ user_id: data.uuid })
        return data
    } catch (err) {
        throw err
    }
}

export async function createOrUpdateVendor(payload) {
    try {
        const { error } = await supabase
            .from('vendors')
            .upsert([{ ...payload }], { onConflict: ['user_id'] })
            .single()

        if (error) {
            throw error
        }
        return true
    } catch (err) {
        throw err
    }
}

export async function updateVendorUsername(userId, newUsername) {
    try {
        const { data, error } = await supabase
            .from('users')
            .update({ username: newUsername })
            .eq('uuid', userId)
            .select('*')

        if (error) {
            throw error
        }
        return data
    } catch (err) {
        console.error('err', err)
        throw err // Re-throw the error for further handling if needed
    }
}

export const isUsernameUnique = async (username) => {
    try {
        const { error } = await supabase
            .from('users')
            .select('username')
            .eq('username', username)
            .single()

        if (error && error.code === 'PGRST116') {
            // No user found with this username
            return true
        }

        // Username exists
        return false
    } catch (err) {
        throw err
    }
}

export async function getUserProfileByUUID(user_uuid) {
    try {
        const { data: userData, error: userError } = await supabase
            .from('users')
            .select('*, vendors (*)')
            .eq('uuid', user_uuid)
            .single()

        const { data: paymentSettingData, error: paymentSettingError } =
            await supabase
                .from('payment_settings')
                .select('*')
                .eq('user_id', userData.uuid)
                .single()

        if (userError) {
            throw Error('User not found!')
        }

        return {
            ...userData,
            profile: {
                ...userData?.vendors,
                paymentSettings: paymentSettingData
                    ? {
                          ...paymentSettingData,
                          current_subscription_status:
                              paymentSettingData?.subscription_current_status,
                      }
                    : null,
            },
        }
    } catch (err) {
        throw err
    }
}
export async function getCustomerDetails(id) {
    try {
        const { data: customerData, error: customerError } = await supabase
            .from('customers')
            .select('*')
            .eq('uuid', id)
            .single()
        if (customerError) {
            throw Error('Customer Not Found.')
        }
        return customerData
    } catch (err) {
        throw err
    }
}

export const uploadImagesOnSupabase = async (files, isCallbackImg = null) => {
    let isCallbackImgUrl = null
    const uploadPromises = files.map(async (file) => {
        const fileExt = file.name.split('.').pop()
        const fileName = `${Date.now()}-${Math.floor(
            Math.random() * 10000000
        )}.${fileExt}`
        const filePath = `public/${fileName}`

        if (isCallbackImg !== null && isCallbackImg === file.name) {
            isCallbackImgUrl = filePath
        }

        let { error: uploadError } = await supabase.storage
            .from('services')
            .upload(filePath, file)

        if (uploadError) {
            throw uploadError
        }
        return filePath
    })

    try {
        const filePaths = await Promise.all(uploadPromises)
        if (isCallbackImg !== null) {
            return {
                imagesPath: filePaths,
                latest_cover_img_ref: isCallbackImgUrl,
            }
        }
        return filePaths
    } catch (error) {
        throw error
    }
}

export const uploadImageOnSupabase = async (file) => {
    const { data, error } = await supabase.storage
        .from('services')
        .upload(`public/${file.name}`, file, {
            upsert: true,
            contentType: file.type,
        })

    if (error) {
        throw new Error(error.message)
    }

    return `https://jhjzstknlfqascmnntgr.supabase.co/storage/v1/object/public/services/public/${file.name}`
}

export const uploadLogoOnSupabase = async (file) => {
    // console.log(file
    const fileExt = file.name.split('.').pop()

    const fileName = `${Date.now()}-${Math.floor(
        Math.random() * 10000000
    )}-${fileExt}`
    const filePath = `logo/${fileName}`

    let { error: uploadError, data: uploadData } = await supabase.storage
        .from('services')
        .upload(filePath, file)

    if (uploadError) {
        throw uploadError
    }

    return filePath
}

export const getUserAndProfileByAuthId = async (auth_id) => {
    try {
        const { data: userData, error: userError } = await supabase
            .from('users')
            .select('*, vendors (*)')
            .eq('auth_id', auth_id)
            .single()

        if (userError) {
            // throw new Error(`Error fetching user data: ${userError.message}`)
            // throw new Error(`User Not Found.`)
            return null
        }
        return { ...userData, profile: userData?.vendors }
    } catch (err) {
        console.error('Error in getUserProfile:', err)
        return null
    }
}

export const getVendorProfileByUserId = async (user_id) => {
    try {
        const { data: userData, error: userError } = await supabase
            .from('vendors')
            .select('*')
            .eq('user_id', user_id)
            .single()

        if (userError) {
            throw new Error(`Error fetching user data: ${userError.message}`)
        }
        return userData
    } catch (err) {
        console.error('Error in get Vendor Profile:', err)
        return {}
    }
}

export const getAllGenerations = async () => {
    try {
        const { data, error } = await supabase.from('generations').select('*')
        if (error) {
            throw error
        }

        return data
    } catch (err) {
        console.error('Error fetching generations:', err)
        return null
    }
}

export const sumGenerations = async () => {
    try {
        const { data, error } = await supabase
            .from('customers')
            .select('generations')
        if (error) {
            throw error
        }
        const sum = data.reduce(
            (acc, customer) => acc + (customer.generations || 0),
            0
        )
        return sum
    } catch (err) {
        console.error('Error fetching generations:', err)
        return null
    }
}

export const countPaidInvoices = async () => {
    try {
        // Fetch the rows where invoice_status is 'paid'
        const { data, error } = await supabase
            .from('stripe_payments')
            .select('*', { count: 'exact' }) // Request exact count
            .eq('invoice_status', 'paid')

        if (error) {
            throw error
        }

        // Return the count of rows where invoice_status is 'paid'
        return data.length
    } catch (err) {
        console.error('Error fetching paid invoices:', err)
        return null
    }
}

export const getAllVendors = async () => {
    try {
        const { data: vendorsData, error: vendorsError } = await supabase.rpc(
            'get_vendors_with_payment_settings'
        )

        if (vendorsError) {
            throw new Error(
                `Error fetching vendors data: ${vendorsError.message}`
            )
        }
        const filteredVendorsData = vendorsData.filter(
            (vendor) => !vendor.is_deleted
        )

        const vendorNamesById = await fetchAllVendorNames()

        const enrichedVendorsData = filteredVendorsData.map((vendor) => ({
            ...vendor,
            name: vendorNamesById[vendor.user_id] || null,
        }))

        return enrichedVendorsData
    } catch (err) {
        console.error('Error in getAllVendors', err)
        return []
    }
}

async function fetchAllVendorNames() {
    try {
        const { data, error } = await supabase
            .from('vendors')
            .select('user_id, name')

        if (error) {
            throw new Error(`Error fetching vendor names: ${error.message}`)
        }

        const vendorNameMap = data.reduce((acc, current) => {
            acc[current.user_id] = current.name
            return acc
        }, {})

        return vendorNameMap
    } catch (error) {
        console.error('Error in fetchAllVendorNames', error)
        return {}
    }
}
export const getAllServices = async (page) => {
    try {
        const { data, error } = await supabase
            .from('services')
            .select(`*`)
            .eq('is_deleted', false)
            .order(page?.sort?.key ? page?.sort?.key : 'created_at', {
                ascending: page?.sort?.order === 'asc' ? true : false,
            })

        // .range((pagination.pageIndex -1) * pagination.pageSize, pagination.pageIndex * pagination.pageSize-1 )

        if (error) {
            throw new Error(`Error fetching vendors data: ${error.message}`)
        }

        return { services: data }
    } catch (err) {
        return err
    }
}

export const getAdminServices = async ({
    pageIndex,
    pageSize,
    sort,
    query,
}) => {
    try {
        let queryBuilder = supabase
            .from('services')
            .select('*', { count: 'exact' })
            .eq('is_deleted', false)
            .order(sort.key, { ascending: sort.order === 'asc' })
            .range((pageIndex - 1) * pageSize, pageIndex * pageSize - 1)

        if (query) {
            queryBuilder = queryBuilder.or(`name.ilike.%${query}%`)
        }

        const { data, error, count } = await queryBuilder

        if (error) {
            throw new Error(`Error fetching services data: ${error.message}`)
        }

        return {
            data: data ?? [],
            count,
        }
    } catch (err) {
        console.error('Error in getAdminServices', err)
        throw err
    }
}

export const getAllProducts = async ({ pageIndex, pageSize, sort, query }) => {
    try {
        let queryBuilder = supabase
            .from('products')
            .select('*', { count: 'exact' })
            .order(sort.key, { ascending: sort.order === 'asc' })
        if (query) {
            queryBuilder = queryBuilder.or(
                `name.ilike.%${query}%,storage.ilike.%${query}%,price.ilike.%${query}%`
            )
        }
        const { data, error, count } = await queryBuilder
        if (error) {
            throw new Error(`Error fetching products data: ${error.message}`)
        }

        const paginatedData = data.slice(
            (pageIndex - 1) * pageSize,
            pageIndex * pageSize
        )

        return {
            data: paginatedData,
            count,
        }
    } catch (err) {
        console.error('Error in get Products', err)
        throw err
    }
}
export const getCustomers = async () => {
    try {
        const { data, error } = await supabase.from('customers').select('*')
        if (error) {
            throw Error('Customers not found!')
        }

        return data
    } catch (err) {
        throw err
    }
}
export const getAllCustomers = async ({ pageIndex, pageSize, sort, query }) => {
    try {
        let queryBuilder = supabase
            .from('customers')
            .select('*', { count: 'exact' })
            .order(sort.key, { ascending: sort.order === 'asc' })

        if (query) {
            queryBuilder = queryBuilder.or(
                `name.ilike.%${query}%,email.ilike.%${query}%,phone.ilike.%${query}%`
            )
        }

        const { data, error, count } = await queryBuilder

        if (error) {
            throw new Error(`Error fetching customers data: ${error.message}`)
        }

        const paginatedData = data.slice(
            (pageIndex - 1) * pageSize,
            pageIndex * pageSize
        )

        return {
            data: paginatedData,
            count,
        }
    } catch (err) {
        console.error('Error in getAllCustomers', err)
        throw err
    }
}
export async function getFeatureProducts() {
    try {
        const { data, error } = await supabase.from('products').select('*')

        if (error) {
            throw Error('Product not found!')
        }

        return data
    } catch (err) {
        throw err
    }
}

export async function getSingleProduct(id) {
    try {
        const { data, error } = await supabase
            .from('products')
            .select('*')
            .eq('uuid', id)
            .single()

        if (error) {
            throw Error('Product not found!')
        }

        return data
    } catch (err) {
        throw err
    }
}

export async function getSingleService(id) {
    try {
        const { data, error } = await supabase
            .from('services')
            .select('*')
            .eq('uuid', id)
            .single()
        if (error) {
            throw Error('User not found!')
        }
        return data
    } catch (err) {
        throw err
    }
}

export async function updateUserPassword(password) {
    try {
        const { data, error } = await supabase.auth.updateUser({
            password,
        })
        if (error) {
            throw error
        }
        return data
    } catch (err) {
        throw err
    }
}

export const getVendorServices = async (vendor_id) => {
    try {
        const { data, error } = await supabase
            .from('vendor_services')
            .select()
            .eq('vender_id', vendor_id)
        if (error) {
            throw new Error(`Error fetching customers data: ${error.message}`)
        }

        return data
    } catch (err) {
        console.error('Error in get customers', err)
        return {}
    }
}

export const createOrUpdateVendorServices = async (selectedServices) => {
    try {
        const { data, error } = await supabase
            .from('vendor_services')
            .upsert(selectedServices, {
                onConflict: ['service_id', 'vender_id'],
            })
            .select('*')
        if (error) {
            throw new Error(`Error while updating vendor services.`)
        }
        return data
    } catch (err) {
        return err
    }
}

export const deleteVendorServices = async (serviceIds, vendor_id) => {
    try {
        const { data: existingServices, error: fetchError } = await supabase
            .from('vendor_services')
            .select('service_id')
            .eq('vender_id', vendor_id)
            .in('service_id', serviceIds)

        if (fetchError) {
            throw new Error('Error while fetching vendor services.')
        }

        if (existingServices.length === 0) {
            // throw new Error('No matching services found for the given vendor.')
        }

        const existingServiceIds = existingServices.map(
            (service) => service.service_id
        )

        if (existingServiceIds?.length > 0) {
            const { data, error: deleteError } = await supabase
                .from('vendor_services')
                .delete()
                .in('service_id', existingServiceIds)
                .eq('vender_id', vendor_id)
            if (deleteError) {
                throw new Error('Error while deleting vendor services.')
            }
        }

        return true
    } catch (err) {
        return err
    }
}

export const getAllVendorPortfolios = async (vendorId) => {
    try {
        const { data, error } = await supabase
            .from('portfolio')
            .select()
            .eq('vendor_id', vendorId)
        if (error) {
            throw error
        }
        return data
    } catch (err) {
        return err
    }
}

export const createVendorPortfolio = async (payload) => {
    try {
        const { data, error } = await supabase
            .from('portfolio')
            .upsert(payload, { onConflict: 'uuid' })
            .single()
        if (error) {
            throw error
        }
        return data
    } catch (err) {
        return err
    }
}

export const deleteVendorPortfolioById = async (portfolio_uuid) => {
    try {
        const { error } = await supabase
            .from('portfolio')
            .delete()
            .eq('uuid', portfolio_uuid)
        if (error) {
            throw error
        }

        return true
    } catch (err) {
        console.log('err', err)
    }
}

export const checkVendorIsBlocked = async (user_id) => {
    try {
        const { data, error } = await supabase
            .from('vendors')
            .select('status,uuid')
            .eq('user_id', user_id)
            .eq('status', 'inactive')
            .single()

        if (data && data.uuid) {
            throw new Error(
                'Your account has been blocked. Please contact support for assistance.'
            )
        }
        // console.log('checkVendorIsBlocked', error)
        if (error) {
            if (error?.code === 'PGRST116') {
                return true
            }
            throw error
        }
    } catch (err) {
        throw err
    }
}

export const getCurrentMonthVendorsCount = async () => {
    try {
        const startOfMonth = moment().utc().startOf('month').toISOString()
        const endOfMonth = moment().utc().endOf('month').toISOString()
        const { error, count } = await supabase
            .from('vendors')
            .select('*', { count: 'exact' })
            .gte('created_at', startOfMonth)
            .lte('created_at', endOfMonth)
        if (error) {
            console.error('Error fetching current month vendors count:', error)
            return null
        }
        return count
    } catch (err) {
        console.error('Error in getCurrentMonthVendorsCount:', err)
        return null
    }
}

export const createOrUpdateService = async (payload = {}) => {
    try {
        const { data, error } = await supabase
            .from('services')
            .upsert([{ ...payload }], {
                onConflict: ['uuid'],
            })
            .single()
        if (error) {
            throw error
        }

        return data
    } catch (err) {
        console.log('err: createOrupdateService', err)
        throw err
    }
}
